import { isNullOrEmpty } from "@common/lib/util"

/**
 * Checks if all values in the given object are undefined.
 *
 * @param {Object} obj - The object to be checked.
 * @returns {boolean} - Returns true if all values in the object are undefined, otherwise false.
 */
export const areAllValuesUndefined = (obj) =>
  Object.values(obj).every((value) => value === undefined)

/**
 * Filters an object by a list of keys.
 *
 * This function accepts an object and a list of keys, and returns a new object
 * that includes only the properties of the original object that are specified
 * in the list of keys. If a key in the list does not exist in the original object, it is ignored.
 *
 * @param {Object|null|undefined} obj - The original object to filter, or null/undefined.
 * @param {Array|string[]} keys - An array of keys to retain in the filtered object. Must be an array.
 * @returns {Object} A new object containing only the keys specified in `keys`, or an empty object if `obj` is null/undefined or `keys` is not an array.
 */
export function filterObjectByKeys(obj, keys) {
  if (
    typeof obj !== "object" ||
    obj === null ||
    isNullOrEmpty(keys) ||
    !Array.isArray(keys)
  ) {
    console.error("TypeError: obj is null/undefined or keys is not an array")
    return obj
  }

  const filteredObj = {}
  keys.forEach((key) => {
    if (Object.hasOwnProperty.call(obj, key)) {
      filteredObj[key] = obj[key]
    }
  })
  return filteredObj
}
/**
 * Replaces or adds a value for a specific key within a given object.
 * If the specified key already exists in the object, its value is updated with the new value.
 * If the key does not exist, it is added to the object with the given value.
 * If the input is invalid, the original object is returned without any modifications.
 *
 * @param {Object} obj - The target object where the value should be replaced or added. Must be a non-null object.
 * @param {string} key - The key for which the value should be replaced or added. Keys are case-sensitive.
 * @param {any} newValue - The new value to assign to the key in the object. This value can be of any type, including string, number, boolean, object, or array.
 *
 * @returns {Object} - Returns the modified object if the parameters are valid, otherwise returns the original object.
 */
export function replaceValueInObject(obj, key, newValue) {
  if (!obj || typeof obj !== "object" || obj === null || !key) {
    return obj
  }
  if (isNullOrEmpty(newValue)) delete obj[key]
  else obj[key] = newValue
  return obj
}

/**
 * Recursively changes the keys of an object based on a provided mapping.
 * It supports nested objects and arrays, ensuring that the structure of the
 * original object is preserved while keys are updated as specified.
 *
 * @param {Object|Array} obj - The original object (or array) whose keys are to be changed.
 *                             Nested objects and arrays are supported.
 * @param {Object} keyMap - An object representing the mapping of old keys to new keys.
 *                          Each key in this object corresponds to a key in the original object that needs to be changed,
 *                          and its value is the new key name.
 * @returns {Object|Array} - A new object (or array) with the keys changed as specified by the keyMap.
 *                           The original object is not modified.
 *
 * @example
 * const originalObject = {
 *   name: "John Doe",
 *   age: 30,
 *   address: {
 *     street: "123 Main St",
 *     city: "Anytown",
 *     country: "Anycountry",
 *   },
 * };
 * const keyMap = {
 *  name: "fullName",
 *  street: "streetAddress",
 *  country: "countryName"
 *};
 *
 * // Generates a new object with the keys renamed according to keyMap.
 */
export function changeKeys(obj, keyMap) {
  if (
    typeof obj !== "object" ||
    obj === null ||
    typeof keyMap !== "object" ||
    keyMap === null
  )
    return obj

  const newObj = Array.isArray(obj) ? [] : {}

  Object.keys(obj).forEach((key) => {
    const newKey = keyMap[key] || key
    const value = obj[key]

    if (typeof value === "object" && value !== null) {
      newObj[newKey] = changeKeys(value, keyMap)
    } else {
      newObj[newKey] = value
    }
  })

  return newObj
}

// Returns the delta between the two objects
export function getObjectDifference(obj1, obj2) {
  const diff = {}
  function compareObjects(obj1, obj2, path) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const newPath = (path ? path + "." : "") + key
        if (!obj2.hasOwnProperty(key)) {
          // Key exists in obj1 but not obj2
          diff[newPath] = {
            obj1: obj1[key],
            obj2: undefined,
          }
        } else if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          // Both values are objects, recursively compare
          compareObjects(obj1[key], obj2[key], newPath)
        } else if (obj1[key] !== obj2[key]) {
          // Key exists in both objects but with different values
          diff[newPath] = {
            obj1: obj1[key],
            obj2: obj2[key],
          }
        }
      }
    }
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        // Key exists in obj2 but not obj1
        const newPath = (path ? path + "." : "") + key
        diff[newPath] = {
          obj1: undefined,
          obj2: obj2[key],
        }
      }
    }
  }
  compareObjects(obj1, obj2, "")
  return diff
}
