import Loader from "@common/loader"
import TooltipWrapper from "@common/tooltip/tooltipWrapper"
import { cva, type VariantProps } from "class-variance-authority"
import { ComponentProps, ReactNode } from "react"

interface ButtonBaseProps {
  children: ReactNode | undefined
  type?: "submit" | "button" | "reset"
  onClick?: (e: any) => void
  disabled?: boolean
  disabledMsg?: string | JSX.Element
  isLoading?: boolean
  loadingText?: string
}

// 🚦Cannot be renamed as CVA destructiures it internally
export const variants = {
  // ☢️ Shared with LinkButton
  variant: {
    primary: "rounded-md bg-primary border text-primary-foreground ",
    secondary: "rounded-md bg-muted ",
    outline: "rounded-md backdrop-blur-md border hover:bg-muted",
    minimal: "rounded-md hover:bg-muted ",
    ghost: "",
    destructive:
      "rounded-md border hover:border-destructive hover:text-destructive ",
    accent: "rounded-md bg-accent text-accent-foreground",
    tabSelected:
      "rounded-md font-medium shadow-sm bg-background text-foreground border text-sm",
    tab: "rounded-md text-muted-secondary border-transparent text-sm",
    rounded: "rounded-full hover:bg-muted",
  },
  size: {
    xs: "size-xs tracking-wide",
    sm: "size-sm",
    md: "text-base py-2 px-4",
    lg: "text-lg py-2 px-8 ",

    icon: "h-8 w-8",
    "icon-sm": "h-4 w-4",
    "no-size": "",
  },
  alignment: {
    center: "justify-center text-center",
    left: "justify-start text-left",
  },
  width: {
    default: "inline-flex",
    full: "w-full flex",
  },
}

export const buttonBaseStyles = // ☢️ shared with LinkButton
  " whitespace-nowrap overflow-x-hidden items-center gap-1 font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed  group relative shrink-0 hover:shadow-buttonShadow dark:hover:shadow-buttonShadowDark "

const buttonVariants = cva(buttonBaseStyles, {
  variants,
  defaultVariants: {
    variant: "primary",
    size: "sm",
    width: "default",
    alignment: "center",
  },
})

export type ButtonVarientProps = VariantProps<typeof buttonVariants>

export type ButtonProps = ButtonBaseProps &
  ButtonVarientProps &
  Omit<ComponentProps<"button">, "className" | "style">

export default function Button({
  children,
  type = "submit",
  onClick,
  disabled = false,
  disabledMsg = "",
  isLoading,
  variant,
  size,
  width,
  alignment,
  loadingText,
  ...restProps
}: ButtonProps) {
  function handleClick(e: any) {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <button
      {...restProps}
      disabled={disabled || isLoading}
      type={type}
      className={buttonVariants({ variant, size, width, alignment })}
      onClick={handleClick}
    >
      <TooltipWrapper
        content={disabledMsg}
        disabled={!disabled}
        position={undefined}
      >
        {isLoading ? <Loader type="spinWithText" text={loadingText} /> : children}
      </TooltipWrapper>
    </button>
  )
}
